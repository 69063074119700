<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <div class="my-md">
                    <a-row :gutter="24">
                        <a-col :span="24">
                            <a-date-picker mode="year" format="YYYY" :value="currentYear" @openChange="isOpen = true" :open="isOpen" @panelChange="yearChange"/>
                            <a-divider type="vertical"></a-divider>
                            <a-tree-select
                                    @change="companyChange"
                                    :placeholder="l('Company')"
                                    style="width: 200px"
                                    allowClear
                                    tree-default-expand-all
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="companyTreeList"
                                    v-model="companyId"
                            />
                            <a-divider type="vertical"></a-divider>
                            <a-tree-select
                                    v-model="departmentId"
                                    style="width: 200px"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="depTreeList"
                                    :placeholder="l('Department')"

                                    allow-clear
                                    @change="SelectDepartmentTree">
                            </a-tree-select>
                            <a-divider type="vertical"></a-divider>
                            <a-button type="primary" @click="exportExcel" :loading="exporting">
                                <a-icon type="export"></a-icon>
                                导出
                            </a-button>
                            <a-divider type="vertical" />
                            <a-button @click="getData">刷新</a-button>
                        </a-col>
                    </a-row>
                </div>
                <div>
                    <a-row>
                        <a-col :span="6">
                            <a-table
                                    :scroll="{ x: 380, y: 660 }"
                                    :columns="columns"
                                    :data-source="tableData"
                                    :pagination="false">
                                <span slot="beginMonth" slot-scope="record">
                                    {{ record | moment("YYYYMM") }}
                                </span>
                            </a-table>
                        </a-col>
                        <a-col :span="18">
                            <div ref="echarts" style="width: 100%; height: 700px"></div>
                        </a-col>
                    </a-row>
                </div>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import * as echarts from 'echarts';
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import {AppComponentBase} from "@/shared/component-base";
    import moment from 'moment'
    import {
        CompanyServiceProxy,
        DepartmentServiceProxy,
        CustomizeReportServiceServiceProxy
    } from "@/shared/service-proxies";
    import {fileDownloadService} from "@/shared/utils";
    import {abpService} from "../../../../shared/abp";

    const columns = [
        {
            title: '年月',
            dataIndex: 'beginMonth',
            align: "center",
            scopedSlots: {customRender: "beginMonth"}
        },
        {
            title: '离职人数',
            dataIndex: 'quitNum',
            align: "center",
            key: 'quitNum',
        },
        {
            title: '入职人数',
            dataIndex: 'entryNum',
            align: "center",
            key: 'entryNum',
        },
        {
            title: '月末人数',
            dataIndex: 'endMonthNum',
            align: "center",
            key: 'endMonthNum',
        },
    ];


    export default {
        name: "employee-count-analysis",
        mixins: [AppComponentBase],
        data(){
            return {
                zh_CN,
                columns,
                //是否导出
                exporting: false,
                //当前年
                currentYear: undefined,
                //是否打开年选择
                isOpen: false,

                //公司ID
                companyId: undefined,
                //部门ID
                departmentId: undefined,
                //公司树列表
                companyTreeList: [],
                //公司接口列表
                companyList: [],
                //部门树列表
                depTreeList: [],
                //部门接口列表
                depList: [],

                //表格数据
                tableData: [],
            }
        },
        mounted() {
            this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
            this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
            this._customizeReportServiceServiceProxy = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);
            this.currentYear = moment();

            this.companyTreeInit();
            if(abpService.abp.userInfo.companyId){
                this.companyId=abpService.abp.userInfo.companyId;
            }
            this.getData();
        },
        methods: {
            getData(){
                this.loading = true;
                this._customizeReportServiceServiceProxy.getYearEmpChangeByOrgID(this.currentYear.get('years'), this.companyId, this.departmentId).then(res=>{
                    this.tableData = res;
                    //图表数据
                    const chartData = {
                        months: [],
                        quitNums: [],
                        entryNums: [],
                        endMonthNums: []
                    }
                    for(let {
                        beginMonth,
                        endMonthNum,
                        entryNum,
                        quitNum
                    } of this.tableData){
                        chartData.months.push(moment(new Date(beginMonth)).format('YYYYMM'));
                        chartData.endMonthNums.push(endMonthNum);
                        chartData.entryNums.push(entryNum);
                        chartData.quitNums.push(quitNum);
                    }

                    const option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        legend: {
                            data: ['离职人数', '入职人数', '月末人数']
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: chartData.months,
                                axisPointer: {
                                    type: 'shadow'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '离入职人数',
                                min: 0,
                                max: 250,
                                interval: 50,
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            },
                            {
                                type: 'value',
                                name: '月末人数',
                                min: 0,
                                max: 500,
                                interval: 100,
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            }
                        ],
                        series: [
                            {
                                name: '离职人数',
                                type: 'bar',
                                color: '#C23531',
                                data: chartData.quitNums
                            },
                            {
                                name: '入职人数',
                                type: 'bar',
                                data: chartData.entryNums
                            },
                            {
                                name: '月末人数',
                                type: 'line',
                                yAxisIndex: 1,
                                data: chartData.endMonthNums
                            }
                        ]
                    };

                    const eChart = echarts.init(this.$refs.echarts);
                    eChart.setOption(option);

                    window.addEventListener('resize', () => {
                        eChart.resize();
                    });
                }).finally(() => {
                    this.loading = false;
                })
            },
            //年选择器改变
            yearChange(e){
                this.isOpen = false;
                this.currentYear = e;
                this.getData();
            },

            companyTreeInit() {
                this.loading = true;
                this._CompanyConfigServiceProxy
                    .getPaged(undefined, "", 100, 0)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName
                                };
                                this.companyTreeList.push(obj);

                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                    });
            },
            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            companyChange(value) {
                this.companyId = value;
                this.getDepTreeList();
                this.getData();
            },
            /**
             * 获取部门
             */
            getDepTreeList() {
                this.departmentId = undefined;
                this.depTreeList = [];
                this.loading=true;
                this._DepartmentServiceProxy.getDepTreeData(this.companyId).finally(()=>{this.loading=false;}).then(res => {
                    this.depList = res;
                    this.depList.forEach(item => {
                        if (!item.parentId) {
                            let obj = {
                                key: item.key,
                                value: item.key.toString(),
                                title: item.title
                            };
                            this.depTreeList.push(obj);
                        }
                    });
                    this.initDepTree(this.depTreeList);

                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * 初始化树形结构数据格式
             */
            initDepTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.depList.forEach((m) => {
                        if (m.parentId == v.key) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.key,
                                value: m.key.toString(),
                                title: m.title
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initDepTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            /**
             * 选择部门
             */
            SelectDepartmentTree(e) {
                this.departmentId = e;
                this.getData();
            },
            exportExcel(){
                this.exporting = true;
                this._customizeReportServiceServiceProxy.getYearEmpChangeByOrgIDToExcel(this.currentYear.get('years'), this.companyId, this.departmentId).then(result => {
                    fileDownloadService.downloadTempFile(result);
                }).finally(()=>{
                    this.exporting = false;
                })
            },
        }
    }
</script>

<style scoped>

</style>
